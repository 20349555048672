@import "../../styles/COLORS.css"; 

.navWrapper {
  width: 100%;
}

.nav {
  background-color: #e9e7e7;
  padding: 0.5rem;
}

/* Logo styles */
.logoContainer {
  display: flex;
  align-items: center;
  padding: 0.5rem;
}

.logoLink {
  display: block;
  width: 100%;
}

.logo {
  max-width: 100%;
  height: auto;
  display: block;
  padding: 0.5rem;
}

/* Navigation links container */
.navLinks {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  font-size: 1rem;
}

/* Individual nav links */
.navLink {
  color: rgb(75, 75, 75);
  text-decoration: none;
  padding: 0.5rem 1rem;
  transition: color 0.2s ease;
  text-transform: uppercase;
  font-weight: 500;
}

.navLink:hover {
  color: rgb(0, 0, 0);
  text-decoration: underline;
}

/* Services button (inherits from navLink) */
.servicesButton {
  text-transform: uppercase;
  font-weight: 500;
}

/* Dropdown menu styles */
.menuItem {
  padding: 0;
}

.menuLink {
  text-decoration: none;
  color: var(--dark-blue);
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  transition: color 0.2s ease;
}

.menuLink:hover {
  color: black;
  text-decoration: underline;
}

/* Responsive styles */
@media screen and (max-width: 860px) {
  .nav {
    padding: 0;
  }

  .logoContainer {
    display: flex;
    justify-content: center;
    padding: 1rem 0.5rem;
  }

  .logo {
    max-width: 80%;
    margin: 0 auto;
    padding: 0;
  }

  .navLinks {
    justify-content: space-between;
    padding: 0.75rem 1.5rem;
    font-size: 0.875rem;
    gap: 0.5rem;
  }

  .navLink {
    padding: 0.5rem;
  }
}

/* Medium-small screens */
@media screen and (max-width: 720px) {
  .navLinks {
    padding: 0.75rem 0.5rem;
    font-size: 0.75rem;
    gap: 0.25rem;
  }

  .navLink {
    padding: 0.5rem 0.25rem;
  }

  .servicesButton {
    padding: 0.5rem 0.25rem !important;
    min-width: unset !important;
  }
}

/* Additional adjustments for smallest screens */
@media screen and (max-width: 600px) {
  .navLinks {
    padding: 0.75rem;
    font-size: 0.875rem;
    gap: 0.5rem;
  }

  .navLink {
    padding: 0.5rem;
  }

  .servicesButton {
    padding: 0.2rem !important;
  }

  .menuLink {
    font-size: .8rem;
  }
}

/* Larger screens */
@media screen and (min-width: 861px) {
  .nav {
    padding: 0 2rem;
  }

  .navLinks {
    font-size: 1rem;
  }
}

@media screen and (min-width: 1200px) {
  .navLinks {
    font-size: 1.125rem;
  }
}