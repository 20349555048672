@import "../../styles/COLORS.css";

.contactPage {
  min-height: 100vh;
  padding: 2rem;
}

.contactContainer {
  max-width: 800px;
  margin: 0 auto;
}

/* Status Message Styles */
.statusMessage {
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 4px;
  font-size: 1rem;
  text-align: center;
  opacity: 0;
  animation: fadeIn 0.3s ease forwards;
}

.success {
  background-color: rgba(0, 200, 83, 0.1);
  color: rgb(0, 150, 62);
  border: 1px solid rgba(0, 200, 83, 0.2);
}

.error {
  background-color: rgba(255, 76, 76, 0.1);
  color: rgb(200, 0, 0);
  border: 1px solid rgba(255, 76, 76, 0.2);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Header Styles */
.contactHeader {
  margin-bottom: 2rem;
  text-align: center;
}

.contactTitle {
  font-size: 2.5rem;
  font-weight: 700;
  color: rgb(75, 75, 75);
  margin: 0 0 1rem;
}

.contactText {
  font-size: 1.25rem;
  color: rgb(75, 75, 75);
  line-height: 1.4;
  margin: 0;
}

/* Form Styles */
.contactForm {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.conLabel,
.formLabel {
  font-size: 1.125rem;
  color: rgb(75, 75, 75);
  font-weight: 500;
  display: block;
  margin-bottom: 0.5rem;
}

.formInput,
.formTextarea {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  color: rgb(75, 75, 75);
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(32, 32, 31, 0.1);
}

.formInput:focus,
.formTextarea:focus {
  outline: none;
  border-color: var(--dark-teal);
  box-shadow: 0 2px 8px rgba(32, 32, 31, 0.15);
}

.formTextarea {
  min-height: 200px;
  resize: vertical;
}

.submitButton {
  font-size: 1.125rem;
  font-weight: 600;
  color: white;
  background-color: var(--dark-teal);
  padding: 1rem 2rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(32, 32, 31, 0.1);
  margin-top: 1rem;
  width: auto;
  align-self: center;
}

.submitButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(32, 32, 31, 0.15);
}

.submitButton:active {
  transform: translateY(0);
}

/* Responsive Styles */
@media screen and (max-width: 1200px) {
  .contactContainer {
    max-width: 600px;
  }

  .contactTitle {
    font-size: 2rem;
  }

  .contactText {
    font-size: 1.125rem;
  }
}

@media screen and (max-width: 900px) {
  .contactPage {
    padding: 1.5rem;
  }

  .contactTitle {
    font-size: 1.75rem;
  }

  .contactText {
    font-size: 1rem;
  }

  .conLabel,
  .formLabel {
    font-size: 1rem;
  }

  .submitButton {
    font-size: 1rem;
    padding: 0.875rem 1.75rem;
  }
}

@media screen and (max-width: 600px) {
  .contactPage {
    padding: 1rem;
  }

  .contactContainer {
    max-width: 100%;
  }

  .contactHeader {
    margin-bottom: 1.5rem;
  }

  .contactTitle {
    font-size: 1.5rem;
  }

  .formInput,
  .formTextarea {
    padding: 0.625rem;
    font-size: 0.9375rem;
  }

  .submitButton {
    width: 100%;
    padding: 0.75rem;
  }
}

/* Larger screens */
@media screen and (min-width: 1600px) {
  .contactContainer {
    max-width: 1000px;
  }

  .contactTitle {
    font-size: 3rem;
  }

  .contactText {
    font-size: 1.5rem;
  }

  .conLabel,
  .formLabel {
    font-size: 1.25rem;
  }

  .formInput,
  .formTextarea {
    font-size: 1.125rem;
    padding: 1rem;
  }

  .submitButton {
    font-size: 1.25rem;
    padding: 1.25rem 2.5rem;
  }
}