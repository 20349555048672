/* Service Page Specific Styles */
.servicePageTitle {
  text-align: center;
  color: rgb(75, 75, 75);
  font-size: 2rem;
  margin-bottom: 2rem;
  font-weight: 600;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.servicePageImage {
  width: 80%;
  margin-left: 10%;
  border-radius: 50% 25% / 25% 40%;
  box-shadow: 0 4px 12px rgba(136, 136, 136, 0.2);
  transition: transform 0.3s ease;
}

.servicePageImage:hover {
  transform: scale(1.02);
}

.servicePageText {
  width: 85%;
  color: rgb(75, 75, 75);
  margin: 2rem 5%;
  font-size: 1.125rem;
  text-align: left;
  line-height: 1.6;
  max-width: 700px;
}

.servicePageBulletsTitle{
  text-align: center;
  color: rgb(75, 75, 75);
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

ul[aria-label="Services offered"] {
  max-width: 750px;
  margin: 0 auto;
  text-align: left; /* This keeps your text aligned normally */
}

.servicePageBullets {
  width: 80%;
  max-width: 700px;
  color: rgb(75, 75, 75);
  margin: 1rem 10%;
  font-size: 1.125rem;
  text-align: left;
  line-height: 1.5;
}

.relevantCompaniesTitle {
  text-align: center;
  color: rgb(75, 75, 75);
  font-size: 1.75rem;
  font-weight: 600;
  margin: 3rem 0 2rem;
}

.companyATag {
  display: flex;
  justify-content: center;
  text-decoration: none;
  transition: opacity 0.2s ease;
}

.companyATag:hover {
  opacity: 0.85;
}

.imageContainer {
  display: flex;
  align-items: center;
  min-height: 100%;
}

/*=== Org Logos === */

.vChiefLogo,
.coleagueLogo {
  max-width: 180px;
  width: 17%;
  margin-bottom: 2rem;
  transition: transform 0.2s ease;
}

.vChiefLogo:hover,
.coleagueLogo:hover {
  transform: translateY(-2px);
}

/* .coleagueLogo{
  width: 17%;
  margin-bottom: 3%;
} */

.TFALogo{
  width: 20%;
  margin-bottom: 3%;
}

.SchustermanLogo{
  width: 15%;
  margin-bottom: 3%;
}

.mindworkslogo{
  width: 15%;
  margin-top: -1%;
  margin-bottom: 3%;
  border-radius: 50%;
  box-shadow: 0px 0px 3px 1px #888888;
}

/* .vChiefLogo{
  width: 17%;
  margin-top: -1%;
  margin-bottom: 4%;
} */

.obranLogo{
  width: 15%;
  margin-top: -1%;
  margin-bottom: 4%;
}

.aerdfLogo{
  width: 15%;
  margin-top: -1%;
  margin-bottom: 4%;
}

.CanopyLogo{
  width: 20%;
  margin-top: -1%;
  margin-bottom: 4%;
}

.catalystEdLogo{
  width: 20%;
  margin-top: -1%;
  margin-bottom: 4%;
}

@media screen and (max-width: 1200px) {
  .servicePageTitle {
    font-size: 1.75rem;
  }

  .servicePageText,
  .servicePageBullets {
    font-size: 1rem;
  }

  .servicePageBulletsTitle,
  .relevantCompaniesTitle {
    font-size: 1.5rem;
  }
  .imageContainer {
    display: flex;
    align-items: center;
    min-height: 100%;
  }
}

@media screen and (max-width: 900px) {
  .servicePageImage {
    width: 40%;
    margin-left: 30%;
    /* margin-bottom: 2rem; */
  }

  .servicePageText {
    width: 80%;
    margin: 1.5rem 10%;
  }

 /* org logos */

  .vChiefLogo,
  .coleagueLogo {
    width: 150px;
    margin: 1rem 0;
  }
  
  /* .coleagueLogo{
    width: 150px;
    margin-top: 3%;
  } */
  
  .TFALogo{
    width: 150px;
  }
  
  .SchustermanLogo{
    width: 150px;
  }
  
  .mindworkslogo{
    width: 90px;
  }
  
  /* .vChiefLogo{
    width: 180px;
  } */

  .obranLogo{
    width: 150px;
  }

  .aerdfLogo{
    width: 180px;
  }

  .CanopyLogo{
    width: 180px;
  }

  .catalystEdLogo{
    width: 180px;
  }
}

@media screen and (max-width: 800px) {
  .servicePageTitle{
    font-size: 32px;
  }
  .servicePageText,
  .servicePageBullets{
    font-size: 18px;
  }
  .servicePageBulletsTitle{
    font-size: 25px;
  }
  .relevantCompaniesTitle{
    font-size: 25px;
  }
  
}

@media screen and (max-width: 600px) {
  .servicePageTitle {
    font-size: 1.5rem;
    padding: 0 1rem;
  }

  .servicePageImage {
    width: 60%;
    margin-left: 20%;
  }

  .servicePageText,
  .servicePageBullets {
    width: 90%;
    margin: 1rem 5%;
    font-size: 0.9375rem;
  }

  .servicePageBulletsTitle,
  .relevantCompaniesTitle {
    font-size: 1.25rem;
    padding: 0 1rem;
  }

  ul[aria-label="Services offered"] {
    padding: 0 1rem;
  }

  .vChiefLogo,
  .coleagueLogo {
    width: 120px;
  }
}