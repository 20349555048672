.serviceDescription {
  width: 100%;
}

.serviceTitle {
  font-size: 1.5rem;
  font-weight: 600;
  color: rgb(75, 75, 75);
  margin: 0 0 1rem 0;
}

.serviceText {
  font-size: 1.125rem;
  line-height: 1.6;
  color: rgb(75, 75, 75);
  margin: 0;
}

@media screen and (max-width: 900px) {
  .serviceTitle {
    font-size: 1.25rem;
    margin-bottom: 0.75rem;
  }

  .serviceText {
    font-size: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .serviceTitle {
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
  }

  .serviceText {
    font-size: 0.9375rem;
  }
}